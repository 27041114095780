import React from "react";
import "./Ticker.css";
import Marquee from "react-fast-marquee";
import global from '../../assets/images/Ticker/global.svg'
import doba from '../../assets/images/Ticker/24.svg'
import { useSelector } from 'react-redux';

const Ticker = ({ data }) => {
  const states = useSelector((state) => state);

  function formatNumber(number) {
    const roundedNumber = parseFloat(number).toFixed(0); 
    return Number(roundedNumber).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
}

  const items = [
    { name: 'Created', value: formatNumber(data.today.created_tokens), procent:(data.daily_change.created_tokens).toFixed(0), daily: true, label: 'Daily: ' },
    { name: 'Bonded', value: formatNumber(data.today.bonded_tokens), procent:(data.daily_change.bonded_tokens).toFixed(0),  daily: true, label: ''},
    { name: 'Buy Vol', value: formatNumber(data.today.buy_volume), procent:(data.daily_change.buy_volume).toFixed(0),  daily: true, label: '' },
    { name: 'Sell Vol', value: formatNumber(data.today.sell_volume), procent:(data.daily_change.sell_volume).toFixed(0),  daily: true, label: '' },
    { name: 'New users', value: formatNumber(data.today.new_users), procent:(data.daily_change.new_users).toFixed(0),  daily: true, label: '' },
    { name: 'Created', value: formatNumber(data.all.created_tokens), daily: false, label: 'Global: ' },
    { name: 'Bonded', value: formatNumber(data.all.bonded_tokens), daily: false, label: '' },
    { name: 'Users', value: formatNumber(data.all.new_users), daily: false, label: '' },
  ];

  const labels = {
    Created: 'Global tokens created',
    Bonded: 'Global tokens successfully bonded',
    Users: 'Global wallets interacted with sunpump.meme'
  };

  const Item = ({item}) => {
    return (
      <div 
        className="ticker-item"
        data-tooltip-id="fear_greed"
        data-tooltip-content={item.daily === false ? labels[item.name] || '' : ''}
      >
        <span className="item-label" style={item.label !== '' ? {paddingLeft: '60px', paddingRight: '5px'} : {}}>{item.label !== '' ? item.label : ''}</span>
        <span className="item-from"><img src={item.daily ? doba : global} width={20}/></span>
        <span>
          <span style={states.Layout.layoutModeType === 'light' ? {color: '#fff', opacity: .6, paddingRight: '6px'} : {color: '#fff', opacity: .6, paddingRight: '6px'}}>{item.name}:</span>
          <span className="value">
            {item.value} {item.daily 
            ? <span style={{ fontWeight: 'bold', fontSize: '14px', paddingLeft: '5px', color: item.procent > 0 ? 'rgb(71, 154, 112)' : 'rgb(220, 31, 29)' }}>{`(${item.procent > 0 ? '+' : ''}${item.procent}%)`}</span> 
            : ''}
          </span>
        </span>
      </div>
    )
  }


  return (
    <>
      <Marquee
        autoFill={true}
        pauseOnHover={true}
      >
        {items.map((item, index) => (
          <div key={index} className="item">
            <Item item={item} />
          </div>
        ))}
      </Marquee>
  
    </>

  );
};

export default Ticker;
