import PropTypes from "prop-types";
import React, {useEffect, useState, useRef, useCallback } from "react";
import {
  Container
} from "reactstrap";
import axios from "axios";
import './pagination.css'
import './styles.css'
import moment from "moment";

//Import Breadcrumb
import TableContainer from '../../components/Common/TableContainer'

//i18n
import { withTranslation } from "react-i18next";

import {
  createColumnHelper
} from '@tanstack/react-table';

//tooltip
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

import {
  Modal,
} from "reactstrap";

//chart
import Chart from './Chart';

import demo from './demo.json'
//redux
import { useDispatch } from "react-redux";
import { logoutUser } from "../../store/actions";

import tweeterSVG from './img/tweeter.svg'
import telegramSVG from './img/telegram.svg'
import wwwSVG from './img/web.svg'
import copy from './img/copy.svg'
import sun from './img/sun.svg'
import tronscan from './img/tronscan.svg'

import { FaPlay } from "react-icons/fa6";
import { SiDevdotto } from "react-icons/si";
import { TiInfoLarge } from "react-icons/ti";
import Checkbox from "react-custom-checkbox";
import { FaCheck } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";

import { defaultTheme } from "react-select";

import premium from './img/premium_icon.png'
import bubblemapIcon from './img/bublemap.png'
import bubblemapSVG from './img/bubble.svg'
import { useSelector } from 'react-redux';

const Bondinglive = props => {

  const dispatch = useDispatch();

  const [baseData, setBaseData] = useState(null);
  const [modal_standard, setmodal_standard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal_data, setmodal_data] = useState([]);
  const columnHelper = createColumnHelper();

  const bonding_progress_Ref = useRef();
  const listed_since_Ref = useRef();
  const [isRunning, setIsRunning] = useState(true);
  const [filteredData, setfilteredData] = useState([])
  const authToken = sessionStorage.getItem('authToken');
  const accessTier = sessionStorage.getItem('accessTier');
  const [isFiltered, setIsFiltered] = useState(false);

  const holders_Ref = useRef();
  const duplicates_Ref = useRef();
  const create_less_Ref = useRef();

  const [filter_bonding_progres, set_filter_bonding_progres] = useState('')
  const [filter_listed_since, set_filter_listed_since] = useState('')
  const [filter_holders, set_filter_holders] = useState('')
  const [filter_duplicates, set_filter_duplicates] = useState('')
  const [filter_create_less, set_filter_create_less] = useState('')
  const [webX, set_webX] = useState(false);
  const [webWWW, set_webWWW] = useState(false);
  const [webTg, set_webTg] = useState(false);
  const [devSold, set_devSold] = useState(false);

  const [suffixBonding, setSuffixBonding] = useState('');
  const [suffixStart, setSuffixStart] = useState('');
  const [suffixHolders, setSuffixHolders] = useState('');
  const [suffixDoubles, setSuffixDoubles] = useState('');
  const [suffixCreate, setSuffixCreate] = useState('');

  const [iframeModal, set_iframeModal] = useState(false);
  const [bubbleMapAddress, setBubbleMapAddress] = useState('');

  const shouldShow = accessTier;

  const columns = [
    columnHelper.accessor(row => row.token.logo, {
      id: 'logo',
      cell: info => {
        return (
          <div style={{maxWidth: '40px'}}>
            <div>{info.cell.row.original.token.has_image 
            ? (
              <a className="text-dark" href={`https://tronscan.org/#/token20/${info.cell.row.original.token.contract_address}`} target="_blank" rel="noopener noreferrer" style={{cursor: 'pointer'}}>
                <img src={`//api.scan.meme/images/256_${info.cell.row.original.token.contract_address}.png`} width={30} height={30} style={{borderRadius: '30px'}}/>
              </a>
            ) 
            : <div className='noneImg'>N/A</div>}</div>
          </div>

        )
      },
      header: () => <span style={{maxWidth: '40px'}}></span>,
      footer: info => info.column.id,
      size: 40,
      enableColumnFilter: false
    }),
    
    columnHelper.accessor(row => row.token.contract_name, {
      id: 'contract_name',
      cell: info => {
        return (
          <div>
            <div className="token-names" style={{minWidth: '140px'}}>
              <b className="name-row btns">
                <span style={{marginRight: '3px'}}>
                  <a className="text-dark" href={`https://tronscan.org/#/token20/${info.cell.row.original.token.contract_address}`} target="_blank" rel="noopener noreferrer" style={{cursor: 'pointer'}}>
                    {info.cell.row.original.token.contract_short_name}
                  </a>
                </span>

                {<button
                  className={'table-btn'} 
                  onClick={()=>copyToClipboard(info.cell.row.original.token.contract_address)}
                  style={{background:'transparent', padding: 0}}
                  data-tooltip-id="create_timestamp"
                  data-tooltip-content={'Copy Token Contract Address'} // contract_address
                >
                  <img src={copy} alt=""/>
                </button>}

                {<button 
                  className={'table-btn'} 
                  onClick={()=>handleClickBuy(info.cell.row.original.token.contract_address)} 
                  style={{background:'transparent', padding: 0}}
                  data-tooltip-id="create_timestamp"
                  data-tooltip-content={'Buy'}
                >
                  <img src={sun} alt=""/>
                </button>}

                {<button 
                  className={'table-btn'} 
                  onClick={()=>handleClickCa(info.cell.row.original.token.contract_address)} 
                  style={{background:'transparent', padding: 0}}
                  data-tooltip-id="create_timestamp"
                  data-tooltip-content={'TronScan'}
                >
                  <img src={tronscan} width={12} alt=""/>
                </button>}
              </b>
              <div className="ticker-row btns">
                <span className="ticker">{info.cell.row.original.token.contract_name}</span>

                {info.cell.row.original.token.twitter_url && 
                  <button 
                    onClick={()=>handleRedirect(info.cell.row.original.token.twitter_url)}  
                    className={'table-btn'} 
                    style={{background:'transparent', padding: 0}}
                    data-tooltip-id="create_timestamp" data-tooltip-content={'Twitter'}
                  >
                    <img src={tweeterSVG} alt=""/>
                  </button>
                }
                {info.cell.row.original.token.website_url && 
                  <button 
                    onClick={()=>handleRedirect(info.cell.row.original.token.website_url)} 
                    className={'table-btn'} 
                    style={{background:'transparent', padding: 0}}
                    data-tooltip-id="create_timestamp"
                    data-tooltip-content={'Website'}
                  >
                    <img src={wwwSVG} alt=""/>
                  </button>
                }
                {info.cell.row.original.token.telegram_url && 
                  <button 
                    onClick={(e)=>{
                    handleRedirect(info.cell.row.original.token.telegram_url)
                  }}  
                  className={'table-btn'} 
                  style={{background:'transparent', padding: 0}}
                  data-tooltip-id="create_timestamp"
                  data-tooltip-content={'Telegram'}
                  >
                    <img src={telegramSVG} alt=""/>
                  </button>
                }

              </div>
            </div>
          </div>

        )
      },
      header: ({column}) => (
        <>
          <div className="title-with-icon left">
            <span
              className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
              onClick={column.getToggleSortingHandler()}
              style={{textAlign: 'left'}}
            >
              Name
            </span>          
            <TiInfoLarge
              size={15}
              color={"#5c6068"}
              data-tooltip-id="coinname"
              data-tooltip-html={'<div style="text-align: left">Displays the full token name, abbreviated name,<br/>social media buttons, purchase buttons, and a link<br/>to Tronscan for detailed token information</div>'}
            /> 
          </div>
          <Tooltip
            id="coinname"
            place={'top'}
          />        
        </>

      ),
      enableColumnFilter: false      
    }),

    columnHelper.accessor(row => parseInt(row.token.create_time_elapsed), {
      id: 'last_since',
      cell: info => {
        return (
          <div className='to-right' style={{position: 'relative'}}>
            <div data-tooltip-id="create_timestamp" data-tooltip-content={info.cell.row.original.token.create_timestamp} style={{position: 'absolute'}}>
              {secondsToTime(info.cell.row.original.token.create_time_elapsed)}
            </div>
          </div>
        )
      },
      header: ({column}) => (
        <>
          <div className="title-with-icon">
            <span 
              className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
              onClick={column.getToggleSortingHandler()}
            >
              Start
            </span>
            <TiInfoLarge
              size={15}
              color={"#5c6068"}
              data-tooltip-id="coinstart"
              data-tooltip-html={'<div style="text-align: left">Date when the token was created</div>'}
            />
        
          </div>     
          <Tooltip
            id="coinstart"
            place={'top'}
          />              
        </>
      ),
      footer: info => info.column.id,
      enableColumnFilter: false,     
    }),

    // columnHelper.accessor(row => row.data.last_price_usd, {
    //   id: 'last_price_usd',
    //   cell: info => {
    //     return (
    //       <div 
    //         className='to-right btns'>
    //         <button
    //           className="table-btn"
    //           onClick={() => {
    //             tog_standard();
    //             setmodal_data(info.cell.row.original.data.prices)
    //           }}
    //         >
    //           {
    //             info.cell.row.original.data.last_price_usd === null 
    //             ? '-' 
    //             : formatNumber(info.cell.row.original.data.last_price_usd)
    //           }
    //         </button>
            
    //       </div>
    //     )
    //   },
    //   header: ({column}) => (
    //     <>
    //       <div className="title-with-icon">
    //         <span
    //           className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
    //           onClick={column.getToggleSortingHandler()}
    //         >
    //           Price<br/>
    //           <span className="small">USD</span>
    //         </span>    
    //         <TiInfoLarge
    //           size={15}
    //           color={"#5c6068"}
    //           data-tooltip-id="priceusd"
    //           data-tooltip-html={'<div style="text-align: left">Current price of the token in US dollars</div>'}
    //         />
        
    //       </div>
    //       <Tooltip
    //         id="priceusd"
    //         place={'top'}
    //       />          
    //     </>

    //   ),
    //   footer: info => info.column.id,
    //   enableColumnFilter: false      
    // }),

    columnHelper.accessor(row => parseFloat(row.data.market_cap_usd), {
      id: 'market_cap',
      cell: info => {
        return (
          <div className='to-right btns'>
            <button
              className="table-btn"
              onClick={() => {
                tog_standard();
                setmodal_data(info.cell.row.original.data.prices)
              }}
            >
              {
                info.cell.row.original.data.market_cap_usd 
                ? `$${(parseFloat(info.cell.row.original.data.market_cap_usd)/1000).toFixed(1)}k` 
                : '-'
              }              
            </button>

          </div>
        )
      },
      header: ({column}) => (
        <>
          <div className="title-with-icon">
            <span 
              className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
              onClick={column.getToggleSortingHandler()}
            >
              Mcap<br/>
              <span className="small">USD</span>
            </span> 
            <TiInfoLarge
              size={15}
              color={"#5c6068"}
              data-tooltip-id="marketcup"
              data-tooltip-html={'<div style="text-align: left">Market cap is the total value of all shares or tokens,<br/>calculated by multiplying the current price by the total number of units</div>'}
            />           
          </div>
          <Tooltip
            id="marketcup"
            place={'top'}
          />    
        </>
      ),
      footer: info => info.column.id,
      enableColumnFilter: false, 
    }),

    columnHelper.accessor(row => parseFloat(row.data.bonding_progress), {
      id: 'bonding_progress',
      cell: info => {
        return (
          <div className={info.cell.row.original.token.launch_pending_event_time === null ? "to-right" : "complete"}>
            <div style={{ display: 'flex', alignItems: 'center'}}>
              {info.cell.row.original.token.launch_pending_event_time === null ?
                (
                  <>
                    <div className="progres-bar">
                      <div className="point" style={{left: `${Math.min((parseFloat(info.cell.row.original.data.bonding_progress)/2.4).toFixed(0), 50)}px`}}></div>
                    </div>
                    <div className="procent">
                      {parseFloat(info.cell.row.original.data.bonding_progress).toFixed(1)}% 
                    </div>                      
                  </>
                )
              :
                (<div className="bonding-complete">
                  Bonding done: {moment(info.cell.row.original.token.launch_pending_event_time).format('mm')} min.
                </div>)
              }
            </div>
          </div>
        )
      },
      header: ({column}) => (
        <>
          <div className="title-with-icon">
            <span
              className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
              onClick={column.getToggleSortingHandler()}
            >
              Bonding<br/>
              <span className="small">Progress completed</span>
            </span>  
            <TiInfoLarge
              size={15}
              color={"#5c6068"}
              data-tooltip-id="bonding"
              data-tooltip-html={'<div style="text-align: left">Bonding progress bar</div>'}
            />          
          </div>
          <Tooltip
            id="bonding"
            place={'top'}
          />         
        </>
      ),
      footer: info => info.column.id,
      enableColumnFilter: false, 
    }),

    columnHelper.accessor(row => parseFloat(row.data.owner.percent), {
      id: 'owner_percent',
      cell: info => {
        let color = '#fff';
        let devValue = parseFloat(info.cell.row.original.data.owner.percent).toFixed(2);
    
        if(devValue === "0.00"){
          color = '#dc1f1d';
        } else if (devValue < 5) {
          color = '#479a70';
        } else if (devValue > 5 && devValue <= 10) {
          color = '#d8b744';
        } else if (devValue > 10 && devValue <= 15) {
          color = '#da7124';
        } else if (devValue > 15) {
          color = '#dc1f1d';
        }
    
        return (
          <div className='to-right btns'>
            <div style={{ color: color, fontWeight: '600' }}>
              {devValue}%
            </div>
            
            <button
              className='table-btn'
              onClick={() => copyToClipboard(info.cell.row.original.token.creator_address)}
              style={{ background: 'transparent', padding: 0 }}
              data-tooltip-id="create_timestamp"
              data-tooltip-content={'Copy Token Creator Address'}
            >
              <img src={copy} alt="" />
            </button>
          </div>
        );
      },
      header: ({ column }) => (
        <>
          <div className="title-with-icon">
            <span
              className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
              onClick={column.getToggleSortingHandler()}
              style={{ textAlign: 'right', display: 'block', cursor: 'pointer' }}
            >
              DEV 
            </span>  
            <TiInfoLarge
              size={15}
              color={"#5c6068"}
              data-tooltip-id="dev"
              data-tooltip-html={'<div style="text-align: left">The colors in the DEV column indicate the largest token holder. <br/> - Green represents ownership of less than 5%, <br/>- Yellow represents 5% to 10%, <br/>- Orange represents 10% to 15%, <br/>- Red represents more than 15% ownership.</div>'}
            />  
          </div>   
          <Tooltip
            id="dev"
            place={'top'}
          />      
        </>

      ),
      footer: info => info.column.id,
      enableColumnFilter: false,
      sortingFn: 'basic',
    }),

    columnHelper.accessor(row => row.data.num_created_tokens_by_wallet, {
      id: 'num_created_tokens_by_wallet',
      cell: info => {
        let color = '#fff';
        let created_tokens = info.cell.row.original.data.num_created_tokens_by_wallet;
    
        if (created_tokens <= 1) {
          color = '#479a70';
        } else if (created_tokens >= 2 && created_tokens <= 3) {
          color = '#d8b744';
        } else if (created_tokens > 3) {
          color = '#dc1f1d';
        }
        return (
          <div className='to-right' style={{color: color, fontWeight: '600'}}>
            {created_tokens}
          </div>

        )
      },
      header: ({column}) => (
        <>
          <div className="title-with-icon">
            <span 
              className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
              onClick={column.getToggleSortingHandler()}
            >
              Tokens<br/>
              <span className="small">
                Created
              </span>            
            </span> 
            <TiInfoLarge
              size={15}
              color={"#5c6068"}
              data-tooltip-id="tokencreator"
              data-tooltip-html={'<div style="text-align: left">Shows the total number of tokens created by the Creator Wallet.</div>'}
            />
     
          </div>  
          <Tooltip
            id="tokencreator"
            place={'top'}
          />                  
        </>

      ),
      footer: info => info.column.id,
      enableColumnFilter: false,     
    }),

    columnHelper.accessor(row => row.data.num_with_same_name, {
      id: 'num_with_same_name',
      cell: info => {
        return (
          <div className='to-right'>
            {info.cell.row.original.data.num_with_same_name} ({info.cell.row.original.data.num_bonded_with_same_name})
          </div>
        )
      },
      header: ({ column }) => (
        <>
          <div className="title-with-icon">
            <span
              className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
              onClick={column.getToggleSortingHandler()}
            >
              Duplicates
            </span>  
            <TiInfoLarge
              size={15}
              color={"#5c6068"}
              data-tooltip-id="dubles"
              data-tooltip-html={'<div style="text-align: left">Information on how many tokens with this name have been created previously:<br/>- First value: Number of duplicates that did not pass bonding<br/>- Second value: Number of duplicates that successfully completed bonding."</div>'}
            />            
          </div>
          <Tooltip
            id="dubles"
            place={'top'}
          /> 
        </>
      ),
      footer: info => info.column.id,
      enableColumnFilter: false      
    }),

  


    (shouldShow === 'MASTER' || shouldShow === 'ADVANCED') && columnHelper.accessor(row => parseFloat(row.data.wallets_stats.new), {
      id: 'wallets_stats_new',
      cell: info => {
        return (
          <div className='to-right'>
            {(parseFloat(info.cell.row.original.data.wallets_stats.new)).toFixed(2)}%
          </div>
        )
      },
      header: ({ column }) => (
        <>
          <div className="title-with-icon">
            <div className="column-header-row">
              <span
                className={`header-title p_i ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
                onClick={column.getToggleSortingHandler()} // Ustawienie handlera kliknięcia
              >
                New
                <img 
                  className="premium_icon" 
                  src={premium} 
                  alt=""
                  data-tooltip-id="p_new"
                  data-tooltip-html={'Premium data'}
                />
              </span>      
            </div>  
            <TiInfoLarge
              size={15}
              color={"#5c6068"}
              data-tooltip-id="p_new"
              data-tooltip-html={'<div style="text-align: left">This is % amount of tokens held by new freshly generated wallets.<br/>Can mean that this is team holdings, insiders etc.</div>'}
            />            
          </div>
          <Tooltip
            id="p_new"
            place={'top'}
          /> 
        </>

      ),
      footer: info => info.column.id,
      enableColumnFilter: false,
    }),

    (shouldShow === 'MASTER' || shouldShow === 'ADVANCED') && columnHelper.accessor(row => parseFloat(row.data.wallets_stats.scam), {
      id: 'wallets_stats_scam',
      cell: info => {
        return (
          <div className='to-right'>
            {(parseFloat(info.cell.row.original.data.wallets_stats.scam)).toFixed(2)}%
          </div>
        )
      },
      header: ({ column }) => (
        <>
          <div className="title-with-icon">
            <div className="column-header-row">
              <span
                className={`header-title p_i ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
                onClick={column.getToggleSortingHandler()} // Ustawienie handlera kliknięcia
              >
                Scammers 
                <img 
                  className="premium_icon" 
                  src={premium} 
                  alt=""
                  data-tooltip-id="p_scam"
                  data-tooltip-html={'Premium data'}
                />
              </span>      
            </div>  
            <TiInfoLarge
              size={15}
              color={"#5c6068"}
              data-tooltip-id="p_scam"
              data-tooltip-html={'<div style="text-align: left">This is % of tokens held by scammers wallets that buy<br/>tokens to sell it very often together in same time</div>'}
            />            
          </div>
          <Tooltip
            id="p_scam"
            place={'top'}
          /> 
        </>

      ),
      footer: info => info.column.id,
      enableColumnFilter: false,
    }),

    (shouldShow === 'MASTER' || shouldShow === 'ADVANCED') && columnHelper.accessor(row => parseFloat(row.data.wallets_stats.user), {
      id: 'wallets_stats_user',
      cell: info => {
        return (
          <div className='to-right'>
            {(parseFloat(info.cell.row.original.data.wallets_stats.user)).toFixed(2)}%
          </div>
        )
      },
      header: ({ column }) => (
        <>
          <div className="title-with-icon">
            <div className="column-header-row">
              <span
                className={`header-title p_i ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
                onClick={column.getToggleSortingHandler()} // Ustawienie handlera kliknięcia
              >
                Traders 
                <img 
                  className="premium_icon" 
                  src={premium} 
                  alt=""
                  data-tooltip-id="p_user"
                  data-tooltip-html={'Premium data'}
                />
              </span>      
            </div>  
            <TiInfoLarge
              size={15}
              color={"#5c6068"}
              data-tooltip-id="p_user"
              data-tooltip-html={'<div style="text-align: left">This is % of tokens held by regular users that trading tokens</div>'}
            />            
          </div>
          <Tooltip
            id="p_user"
            place={'top'}
          /> 
        </>

      ),
      footer: info => info.column.id,
      enableColumnFilter: false,
    }),

    shouldShow === 'MASTER' && columnHelper.accessor(row => parseFloat(row.token.contract_address), {
      id: 'contract_address',
      cell: info => {
        return (
          <div className="to-right btns bubbles">
            <button 
              className='btn' 
              onClick={()=>{
                tog_iframeModal()
                setBubbleMapAddress(info.cell.row.original.token.contract_address)
              }}
              style={{padding: 0}}
            >
              <img src={bubblemapSVG} width={34} alt=""/>
            </button>
          </div>
        )
      },
      header: ({ column }) => (
        <>
          <div className="title-with-icon">
            <div className="column-header-row">
              <span
                className={`header-title`}
                // onClick={column.getToggleSortingHandler()} // Ustawienie handlera kliknięcia
              >
                Bubble<br/>
                <span className="small" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px'}}>
                  <b>Maps</b>
                  <img 
                    className="premium_icon" 
                    src={premium} 
                    alt=""
                    data-tooltip-id="p_new"
                    data-tooltip-html={'Premium data'}
                  />
                </span>
              </span>      
            </div>
            <span style={{position: 'relative', top: '3px', right: '6px'}}>
              <TiInfoLarge
                size={15}
                color={"#5c6068"}
                data-tooltip-id="p_new"
                data-tooltip-html={'<div style="text-align: left">A visual representation of blockchain transactions where each bubble represents a wallet. By connecting these bubbles, <br/> you can trace the flow of tokens, revealing the relationships between wallets in real-time!</div>'}
              />               
            </span>  
           
          </div>
          <Tooltip
            id="p_new"
            place={'top'}
          /> 
        </>

      ),
      footer: info => info.column.id,
      enableColumnFilter: false,
    }),




    columnHelper.accessor(row => parseFloat(row.data.holders_list?.length), {
      id: 'holders',
      cell: info => {
        let color = ''
        let bg = ''
        const holders = parseFloat(info.cell.row.original.data.holders_list?.length) || 0
        const procent = info.cell.row.original.data.holder
        const procentInt = procent ? parseFloat(procent).toFixed(0) : null

        if(procentInt === 0){
          bg = "transparent"
          color = '#000'
        }else if(procentInt <= 5){
          bg = '#479a70';
          color = '#fff';
        }else if(procentInt > 5 && procentInt <= 10){
          bg = '#d8b744';
          color = '#000';
        }else if(procentInt > 10 && procentInt <= 20){
          bg = '#da7124';
          color = '#fff';
        }else if(procentInt > 20){
          bg = '#dc1f1d';
          color = '#fff';
        }

        return (
          <div 
            className='to-right btns'>
              <button
                className="table-btn"
                onClick={() => {
                  if(holders !== 0){
                    tog_standard();
                    setmodal_data(info.cell.row.original)                    
                  }
                }}
                style={{backgroundColor: bg, color: color, minWidth: '35px'}}
              >
                {holders}
              </button>
              
          </div>
        )
      },
      header: ({column}) => (
        <>
          <div className="title-with-icon">
            <span 
              className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
              onClick={column.getToggleSortingHandler()}
              style={{ textAlign: 'right', display: 'block', cursor: 'pointer' }}
            >
              <span>Holders</span> 
            </span>  
            <TiInfoLarge
              size={15}
              color={"#5c6068"}
              data-tooltip-id="Holders"
              data-tooltip-html={'<div style="text-align: left">The colors in the HOLDERS column indicate the largest token holder. <br/> - Green represents ownership of less than 5, <br/>- Yellow represents 5 to 10, <br/>- Orange represents 10 to 20, <br/>- Red represents more than 20 ownership.</div>'}
            />
        
          </div> 
          <Tooltip
            id="Holders"
            place={'top'}
          />                 
        </>


      ),
      footer: info => info.column.id,
      enableColumnFilter: false,
      sortingFn: 'basic',    
    }),
    
    columnHelper.accessor(row => parseFloat(row.data.holder), {
      id: 'nest_holder',
      cell: info => {
        let color = ''
        const procent = info.cell.row.original.data.holder
        const procentInt = procent ? parseFloat(procent).toFixed(0) : null

        if(procentInt === null){
          color = "transparent"
        }else if(procentInt <= 5){
          color = '#479a70';
        }else if(procentInt > 5 && procentInt <= 10){
          color = '#d8b744';
        }else if(procentInt > 10 && procentInt <= 20){
          color = '#da7124';
        }else if(procentInt > 20){
          color = '#dc1f1d';
        }

        return (
          <div className='to-right'>
            <span style={{color: color, fontWeight: '600'}}>
              {info.cell.row.original.data.holder}%
            </span>

          </div>
        )
      },
      header: ({ column }) => (
        <>
          <div className="title-with-icon">
            <span
              className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
              onClick={column.getToggleSortingHandler()}
              style={{ textAlign: 'right', display: 'block', cursor: 'pointer' }}
            >
              Top holder
            </span>  
            <TiInfoLarge
              size={15}
              color={"#5c6068"}
              data-tooltip-id="topholders"
              data-tooltip-html={'<div style="text-align: left">Displays the percentage value of the largest <br/>portfolio relative to the total value of all <br/> portfolios in the list of holders.</div>'}
            />
  
          </div> 
          <Tooltip
            id="topholders"
            place={'top'}
          />                       
        </>

      ),
      footer: info => info.column.id,
      enableColumnFilter: false      
    }),

    columnHelper.accessor(row => parseFloat(row.data.swaps_last_5_minutes), {
      id: 'swaps_last_5_minutes',
      cell: info => {
        return (
          <div className='to-right'>
            {info.cell.row.original.data.swaps_last_5_minutes}
          </div>
        )
      },
      header: ({column}) => (
        <>
          <div className="title-with-icon">
            <span
              className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
              onClick={column.getToggleSortingHandler()}
            >
              Tx<br/>
              <span className="small">5 min.</span>
            </span>
            <TiInfoLarge
              size={15}
              color={"#5c6068"}
              data-tooltip-id="tx5"
              data-tooltip-html={'<div style="text-align: left">Displays the number of swaps that have occurred in the last 5 minutes</div>'}
            />      
          </div>
          <Tooltip
            id="tx5"
            place={'top'}
          /> 
        </>

      ),
      footer: info => info.column.id,
      enableColumnFilter: false      
    }),

    columnHelper.accessor(row => parseFloat(row.data.swaps), {
      id: 'swaps',
      cell: info => {
        return (
          <div className='to-right'>
            {info.cell.row.original.data.swaps}
          </div>

        )
      },
      header: ({column}) => (
        <>
          <div className="title-with-icon">
            <span 
              className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
              onClick={column.getToggleSortingHandler()}
            >
              Tx<br/>
              <span className="small">Total</span>
            </span> 
            <TiInfoLarge
              size={15}
              color={"#5c6068"}
              data-tooltip-id="tx"
              data-tooltip-html={'<div style="text-align: left">Displays the total number of swaps that have occurred.</div>'}
            />            
          </div>
          <Tooltip
            id="tx"
            place={'top'}
          /> 
        </>

      ),
      footer: info => info.column.id,
      enableColumnFilter: false      
    }),

    columnHelper.accessor(row => parseFloat(row.data.volume_last_5_minutes), {
      id: 'volume_last_5_minutes',
      cell: info => {
        return (
          <div className='to-right'>
            ${(parseFloat(info.cell.row.original.data.volume_last_5_minutes)/1000).toFixed(1)}k
          </div>
        )
      },
      header: ({column}) => (
        <>
          <div className="title-with-icon">
            <span 
              className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
              onClick={column.getToggleSortingHandler()}
            >
              Vol.<br/>
              <span className="small">5 min.</span>
            </span> 
            <TiInfoLarge
              size={15}
              color={"#5c6068"}
              data-tooltip-id="vol5"
              data-tooltip-html={'<div style="text-align: left">Total trading volume in TRX for the last 5 minutes</div>'}
            />              
          </div>
          <Tooltip
            id="vol5"
            place={'top'}
          /> 
        </>
      ),
      footer: info => info.column.id,
      enableColumnFilter: false      
    }),

    columnHelper.accessor(row => parseFloat(row.data.volume_usd), {
      id: 'volume',
      cell: info => {
        return (
          <div className='to-right'>
            {/* {info.cell.row.original.data.volume} */}
            ${(parseFloat(info.cell.row.original.data.volume_usd)/1000).toFixed(1)}k
          </div>
        )
      },
      header: ({column}) => (
        <>
          <div className="title-with-icon">
            <span 
              className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
              onClick={column.getToggleSortingHandler()}
            >
              Vol.<br/>
              <span className="small">Total</span>
            </span>   
            <TiInfoLarge
              size={15}
              color={"#5c6068"}
              data-tooltip-id="volT"
              data-tooltip-html={'<div style="text-align: left">Total trading volume in TRX, representing the sum of all trades conducted</div>'}
            />         
          </div>
          <Tooltip
            id="volT"
            place={'top'}
          /> 
        </>
      ),
      footer: info => info.column.id,
      enableColumnFilter: false      
    }),

    columnHelper.accessor(row => parseFloat(row.data.buysell_last_1_minutes), {
      id: 'buysell_last_1_minutes',
      cell: info => {
        return (
          <div className='to-right'>
            {(parseFloat(info.cell.row.original.data.buysell_last_1_minutes)/1000).toFixed(2)}k
          </div>
        )
      },
      header: ({ column }) => (
        <>
          <div className="title-with-icon">
            <div className="column-header-row">
              <span
                className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
                onClick={column.getToggleSortingHandler()} // Ustawienie handlera kliknięcia
              >
                Buy/Sell<br/>
                <span className="small">1 min.</span>
              </span>      
            </div>  
            <TiInfoLarge
              size={15}
              color={"#5c6068"}
              data-tooltip-id="bs1"
              data-tooltip-html={'<div style="text-align: left">Total trading volume in TRX, which sums up all purchases<br/>and sales over the specified period</div>'}
            />            
          </div>
          <Tooltip
            id="bs1"
            place={'top'}
          /> 
        </>

      ),
      footer: info => info.column.id,
      enableColumnFilter: false,
    }),

    columnHelper.accessor(row => parseFloat(row.data.buysell_last_3_minutes), {
      id: 'buysell_last_3_minutes',
      cell: info => {
        return (
          <div className='to-right'>
            {(parseFloat(info.cell.row.original.data.buysell_last_3_minutes)/1000).toFixed(2)}k
          </div>
        )
      },
      header: ({ column }) => (
        <>
          <div className="title-with-icon">
            <div className="column-header-row">
              <span 
                className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
                onClick={column.getToggleSortingHandler()}
              >
                Buy/Sell<br/>
                <span className="small">3 min.</span>
              </span>
            </div>  
            <TiInfoLarge
              size={15}
              color={"#5c6068"}
              data-tooltip-id="bs3"
              data-tooltip-html={'<div style="text-align: left">Total trading volume in TRX for the last 3 minutes, including the sum of all purchases and sales</div>'}
            />          
          </div>
          <Tooltip
            id="bs3"
            place={'top'}
          /> 
        </>

      ),
      footer: info => info.column.id,
      enableColumnFilter: false      
    }),

    columnHelper.accessor(row => parseFloat(row.data.buysell_last_5_minutes), {
      id: 'buysell_last_5_minutes',
      cell: info => {
        return (
          <div className='to-right'>
            {(parseFloat(info.cell.row.original.data.buysell_last_5_minutes)/1000).toFixed(2)}k
          </div>
        )
      },
      header: ({ column }) => (
        <>
          <div className="title-with-icon last">
            <span 
              className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
              onClick={column.getToggleSortingHandler()}
            >
              Buy/Sell<br/>
              <span className="small">5 min.</span>
            </span>  
            <TiInfoLarge
              size={15}
              color={"#5c6068"}
              data-tooltip-id="bs5"
              data-tooltip-html={'<div style="text-align: left">Total trading volume in TRX for the last 3 minutes, including the sum of all purchases and sales</div>'}
            />           
          </div>
          <Tooltip
            id="bs5"
            place={'left'}
          /> 
        </>

      ),
      footer: info => info.column.id,
      enableColumnFilter: false      
    }),

  ].filter(Boolean);;
  
  const columns_holders_list = [
    columnHelper.accessor(row => row.amount, {
      id: 'amount',
      cell: info => {
        return (
          <div>
            {formatNumberPrice(info.cell.row.original.amount)}
          </div>
        )
      },
      header: () => <span>Amount</span>,
      enableColumnFilter: false      
    }),
    columnHelper.accessor(row => row.amount_percent, {
      id: 'amount_percent',
      cell: info => {
        return (
          <div className='to-right'>
            {parseFloat(info.cell.row.original.amount_percent).toFixed(2)}%
          </div>

        )
      },
      header: () => <span style={{ textAlign: 'right', display: 'block' }}>Percent</span>,
      enableColumnFilter: false      
    }),
    columnHelper.accessor(row => row.wallet_address, {
      id: 'wallet_address',
      cell: info => {
        return (
          <div className='to-right'>
            
            {info.cell.row.original.creator 
              ? <span data-tooltip-id="dev" data-tooltip-content={'Token Creator'}><SiDevdotto size={30} color={'#2d9863'} style={{marginRight: '10px'}}/> </span>
              : ''
            }
            <a 
              className="text-dark"
              href={`https://tronscan.org/#/address/${info.cell.row.original.wallet_address}`} 
              target="_blank" 
              rel="noopener noreferrer"
              style={{color: defaultTheme.colors.primary, cursor: 'pointer'}}
            >
              {info.cell.row.original.wallet_address}
            </a>
            <Tooltip
              id="dev"
              place={'top'}
            />
          </div>
        )
      },
      header: () => <span style={{ textAlign: 'right', display: 'block' }}>Address</span>,
      enableColumnFilter: false      
    }),
  ];


  const tog_iframeModal = () => {
    set_iframeModal(!iframeModal);
    if(iframeModal){
      setBubbleMapAddress('')
    }
  };

  const tog_standard = () => {
    setmodal_standard(!modal_standard);
    if(modal_standard){
      setmodal_data([])
    }
  };

  const handleClickBuy = (tokenAdress) => {
    const url = 'https://sunpump.meme/token/' + tokenAdress;
    window.open(url, '_blank');
  };

  const handleClickCa = (tokenAdress) => {
    const url = 'https://tronscan.org/#/token20/' + tokenAdress;
    window.open(url, '_blank');
  };

  const handleRedirect = (url) => {
    window.open(url, '_blank');  
  };

  function sortAndGetTop10_2(arr) {
    const creatorAddress = arr.token.creator_address;
    const top10 = arr.data.holders_list
      .map(o => {
        o.creator = o.wallet_address === creatorAddress;
        return o;
      })
      .sort((a, b) => b.amount_percent - a.amount_percent)
      .slice(0, 10);
    return top10;
  };

  function formatNumberPrice(numberString) {
    let number = parseFloat(numberString);
    let roundedNumber = number.toFixed(2);
    let parts = roundedNumber.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  function transformArray(inputArray) {
    const transformedArray = inputArray.map(item => {
        return {
            time: moment(item[0]).valueOf() / 1000,
            value: parseFloat(item[1])
        };
    });
    transformedArray.sort((a, b) => a.time - b.time);
    const uniqueArray = transformedArray.filter((item, index, self) => {
        return index === 0 || item.time !== self[index - 1].time;
    });
    return uniqueArray;
  };

  function secondsToTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const hoursString = hours.toString().padStart(2, '0');
    const remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    const minutesString = minutes.toString().padStart(2, '0');
    const remainingSecondsFinal = remainingSeconds % 60;
    const secondsString = remainingSecondsFinal.toFixed(0).padStart(2, '0');
  
    return `${hoursString}:${minutesString}:${secondsString}`;
  };

  const copyToClipboard = (address) => {
    navigator.clipboard.writeText(address)
      .catch((err) => {
        console.error('Błąd podczas kopiowania: ', err);
      });
  };

  function sortAndGetTop1(holdersList) {
    return holdersList
      .sort((a, b) => parseFloat(b.amount_percent) - parseFloat(a.amount_percent))
      .slice(0, 1);
  };

  function addHolderParameter(coins) {
    return coins.map(obj => {
      const holdersList = obj.data.holders_list;
      const topHolder = sortAndGetTop1(holdersList);
      const holder = topHolder.length > 0 ? parseFloat(topHolder[0].amount_percent).toFixed(2) : '0.00';
      return {
        ...obj,
        data: {
          ...obj.data,
          holder: holder
        }
      };
    });
  };

  const resetFilters = () => {
    set_filter_bonding_progres('')
    set_filter_listed_since('')
    set_filter_holders('')
    set_filter_duplicates('')
    set_filter_create_less('')
    set_webX(false)
    set_webWWW(false)
    set_webTg(false)
    set_devSold(false)
  };

  const toggleTimer = () => {
    setIsRunning(!isRunning);
  };

  const filterDatasFun = (data) => {
    if (!data) {
      setfilteredData([]);
      return;
    }

    const filters = [];
  
    if (filter_bonding_progres) {
      const bondingProgressThreshold = parseFloat(bonding_progress_Ref.current.value);
      filters.push(item => parseFloat(item.data.bonding_progress) > bondingProgressThreshold);
    }
  
    if (filter_listed_since) {
      const listedSinceThreshold = listed_since_Ref.current.value * 60;
      filters.push(item => parseFloat(item.token.create_time_elapsed) <= listedSinceThreshold);
    }

    if (filter_holders) {
      const holdersFilter = holders_Ref.current.value;
      filters.push(item => parseFloat(item.data.holders_list?.length) > holdersFilter);
    }

    if (filter_duplicates) {
      const duplicatesFilter = duplicates_Ref.current.value;
      filters.push(item => parseFloat(item.data.num_with_same_name) < duplicatesFilter);
    }

    if (filter_create_less) {
      const createTokensFilter = create_less_Ref.current.value;
      filters.push(item => parseFloat(item.data.num_created_tokens_by_wallet) < createTokensFilter);
    }



    if(webWWW){
      filters.push(item => item.token.website_url !== '');
    }

    if(webX){
      filters.push(item => item.token.twitter_url !== '');
    }

    if(webTg){
      filters.push(item => item.token.telegram_url !== '');
    }

    if(devSold){
      filters.push(item => parseFloat(item.data.owner.percent).toFixed(2) !== "0.00");
    }

  
    const filteredDataTmp = data.filter(item => filters.every(filter => filter(item)));
  
    return filteredDataTmp;
  };

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await axios.get('https://api.scan.meme/api/dashboard/latest', {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Authorization': authToken
        }
      });

      setfilteredData(filterDatasFun(response.data.coins))
      setBaseData(response.data.coins);

      // setBaseData(demo.coins)
      // setfilteredData(filterDatasFun(demo.coins))

      setLoading(false)
    } catch (error) {
      console.error(error);
      setLoading(false)
    }
  };


  useEffect(() => {
    fetchData();
    let interval;
    
    if (isRunning) {
      interval = setInterval(() => {
        fetchData();
      }, 3000);
    }

    return () => clearInterval(interval);
  }, [isRunning]);

  useEffect(() => {
    if(baseData?.length > 0){
      setfilteredData(filterDatasFun(baseData))
    }
  }, [
    filter_bonding_progres, 
    filter_listed_since,
    filter_holders,
    filter_duplicates,
    filter_create_less,
    webWWW,
    webX,
    webTg,
    devSold
  ]);

  useEffect(()=>{
    if(authToken === null){
      dispatch(logoutUser());
      window.location.href = '/login';
    }
  },[]);

  useEffect(()=>{
    if(
      filter_bonding_progres !== '' || 
      filter_listed_since !== '' || 
      filter_holders !== '' || 
      filter_duplicates !== '' || 
      filter_create_less !== '' || 
      webWWW === true ||
      webX === true ||
      webTg === true ||
      devSold === true
    ){
      setIsFiltered(true)
    }else{
      setIsFiltered(false)
    }
  },[
    filter_bonding_progres, 
    filter_listed_since, 
    filter_holders,
    filter_duplicates,
    filter_create_less,
    webWWW, 
    webX, 
    webTg, 
    devSold
  ]);


  const handleFocusDoubles = useCallback(() => {
    if (duplicates_Ref.current) {
      const isActive = document.activeElement === duplicates_Ref.current;
      if (isActive || filter_duplicates.trim() !== '') {
        filter_duplicates && setSuffixDoubles('copy')
      } else {
        setSuffixDoubles('');
      }
    }
  }, [filter_duplicates]);
  useEffect(() => {
    handleFocusDoubles();
  }, [filter_duplicates, handleFocusDoubles]);

  const handleFocusBonding = useCallback(() => {
    if (bonding_progress_Ref.current) {
      const isActive = document.activeElement === bonding_progress_Ref.current;
      if (isActive || filter_bonding_progres.trim() !== '') {
        filter_bonding_progres && setSuffixBonding('percent');
      } else {
        setSuffixBonding('');
      }
    }
  }, [filter_bonding_progres]);
  useEffect(() => {
    handleFocusBonding();
  }, [filter_bonding_progres, handleFocusBonding]);

  const handleFocusStart = useCallback(() => {
    if (listed_since_Ref.current) {
      const isActive = document.activeElement === listed_since_Ref.current;
      if (isActive || filter_listed_since.trim() !== '') {
        filter_listed_since && setSuffixStart('min.');
      } else {
        setSuffixStart('');
      }
    }
  }, [filter_listed_since]);
  useEffect(() => {
    handleFocusStart();
  }, [filter_listed_since, handleFocusStart]);

  const handleFocusHolders = useCallback(() => {
    if (holders_Ref.current) {
      const isActive = document.activeElement === holders_Ref.current;
      if (isActive || filter_holders.trim() !== '') {
        filter_holders && setSuffixHolders('holders')
      } else {
        setSuffixHolders('');
      }
    }
  }, [filter_holders]);
  useEffect(() => {
    handleFocusHolders();
  }, [filter_holders, handleFocusHolders]);

  const handleFocusCreate = useCallback(() => {
    if (create_less_Ref.current) {
      const isActive = document.activeElement === create_less_Ref.current;
      if (isActive || filter_create_less.trim() !== '') {
        filter_create_less && setSuffixCreate('tokens')
      } else {
        setSuffixCreate('');
      }
    }
  }, [filter_create_less]);
  useEffect(() => {
    handleFocusCreate();
  }, [filter_create_less, handleFocusCreate]);



  const idsToRemove = ["wallets_stats_new", "wallets_stats_scam", "wallets_stats_user"];

  function removeItemsById(array, idsToRemove) {
    return array.filter(item => !idsToRemove.includes(item.id));
  }

  function checkWalletsStatsExistence(data) {
    for (let i = 0; i < data.length; i++) {
      if (!data[i].data || !data[i].data.hasOwnProperty('wallets_stats')) {
        return false; 
      }
    }
    return true;
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          
          <div className="settings"> 
            <div className="filter">
              <div className="checkboxes">
                <span
                  data-tooltip-id="checkboxes"
                  data-tooltip-html={'<b>Enabled:</b> Displays only tokens with a Twitter page.<br/><b>Disabled:</b> Shows all tokens, including those without a Twitter page.'}
                >
                  <Checkbox
                    checked={webX}
                    onChange={()=>set_webX(!webX)}
                    icon={<FaCheck color="#01E9BD"/>}
                    borderColor="rgba(186, 186, 186, 0.507)"
                    borderRadius={1}
                    size={15}
                    label="Twitter"
                  />                  
                </span>
                <span
                  data-tooltip-id="checkboxes"
                  data-tooltip-html={'<b>Enabled:</b> Displays only tokens with a Website. <br/><b>Disabled:</b> Shows all tokens, including those without a Website.'}
                >
                  <Checkbox
                    checked={webWWW}
                    onChange={()=>set_webWWW(!webWWW)}
                    icon={<FaCheck color="#01E9BD"/>}
                    borderColor="rgba(186, 186, 186, 0.507)"
                    borderRadius={1}
                    size={15}
                    label="Website"
                  />
                </span>
                <span
                  data-tooltip-id="checkboxes"
                  data-tooltip-html={'<b>Enabled:</b> Displays only tokens with a Telegram page.<br/><b>Disabled:</b> Shows all tokens, including those without a Telegram page.'}
                >
                  <Checkbox
                    checked={webTg}
                    onChange={()=>set_webTg(!webTg)}
                    icon={<FaCheck color="#01E9BD"/>}
                    borderColor="rgba(186, 186, 186, 0.507)"
                    borderRadius={1}
                    size={15}
                    label="Telegram"
                  />                  
                </span>
                <span
                  data-tooltip-id="checkboxes"
                  data-tooltip-html={'<b>Enabled:</b> Hides tokens where the developer (DEV) has sold all tokens,<br/><b>Disabled:</b> Show tokens where dev still HODL<br/>'}
                >
                  <Checkbox
                    checked={devSold}
                    onChange={()=>set_devSold(!devSold)}
                    icon={<FaCheck color="#01E9BD"/>}
                    borderColor="rgba(186, 186, 186, 0.507)"
                    borderRadius={1}
                    size={15}
                    label="Dev sold"
                  />                  
                </span>
              </div>
              
              <div className="input-wrapper">
                <input 
                  className="filter-search" 
                  ref={holders_Ref} type="number" 
                  placeholder="Holders > X" 
                  value={filter_holders} 
                  onFocus={handleFocusHolders}
                  onBlur={handleFocusHolders} 
                  onChange={e => {
                    set_filter_holders(e.target.value)
                  }}
                  style={{paddingRight: '50px'}}
                />
                <span className="input-suffix">{suffixHolders}</span>
              </div>
              <div className="input-wrapper">
                <input 
                  className="filter-search" 
                  ref={duplicates_Ref} 
                  type="number" 
                  placeholder="Duplicates < X" 
                  value={filter_duplicates}
                  onFocus={handleFocusDoubles}
                  onBlur={handleFocusDoubles} 
                  onChange={e => {
                    set_filter_duplicates(e.target.value)
                  }}
                />
                <span className="input-suffix">{suffixDoubles}</span>             
              </div>
              <div className="input-wrapper">
                <input 
                  className="filter-search" 
                  ref={create_less_Ref} 
                  type="number" 
                  placeholder="Tokens created < X" 
                  value={filter_create_less} 
                  onFocus={handleFocusCreate}
                  onBlur={handleFocusCreate} 
                  onChange={e => {
                    set_filter_create_less(e.target.value)
                  }}
                  />
                  <span className="input-suffix">{suffixCreate}</span>                
              </div>
              <div className="input-wrapper">
                <input 
                  className="filter-search" 
                  ref={listed_since_Ref} 
                  type="number" 
                  placeholder="Start < X min." 
                  value={filter_listed_since} 
                  onFocus={handleFocusStart}
                  onBlur={handleFocusStart}
                  onChange={e => {
                    set_filter_listed_since(e.target.value)
                  }}
                  />
                  <span className="input-suffix">{suffixStart}</span>                
              </div>
              <div className="input-wrapper">
                <input 
                  className="filter-search" 
                  ref={bonding_progress_Ref} 
                  type="number" 
                  placeholder="Bonding > X %" 
                  value={filter_bonding_progres} 
                  onFocus={handleFocusBonding}
                  onBlur={handleFocusBonding} 
                  onChange={e => {
                    set_filter_bonding_progres(e.target.value)
                  }}
                  />
                  <span className="input-suffix">{suffixBonding}</span>                
              </div>

              <button 
                className="reset"
                onClick={resetFilters}
              >
                <IoClose/>
              </button>
            </div>
            <div className="refresh">
              <button onClick={toggleTimer} className="btnSimple" style={isRunning ? {color: '#2d9863'} : {color: '#666666'}}>
                <FaPlay size={14}/> {isRunning ? <span className="running-label">Fetching Data</span> : <span className="running-label">Fetch Paused</span>}
                <i className={`bx bx-cog ${isRunning ? 'bx-spin' : ''}`}/>
              </button>
            </div>
          </div>

          <Modal
            isOpen={iframeModal}
            toggle={() => {
              tog_iframeModal();
            }}
            size="xl"
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                BubbleMap
              </h5>
              <button
                type="button"
                onClick={() => {
                  set_iframeModal(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{padding: '0px', marginBottom: '-10px'}}>
              <iframe
                src={`https://app.insightx.network/bubblemaps/tron/${bubbleMapAddress}`}
                width="1138" //(Whatever you use, this is just an example)
                height="640" //(Whatever you use, this is just an example)
              ></iframe>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  tog_iframeModal();
                }}
                className="btn btn-secondary "
                data-dismiss="modal"
                style={{background: '#212227', border:0}}
              >
                Close
              </button>
            </div>
          </Modal>

          <Modal
            isOpen={modal_standard}
            toggle={() => {
              tog_standard();
            }}
            size="lg"
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                {modal_data?.data ? 'Holders list' : 'Price chart'}
              </h5>
              <button
                type="button"
                onClick={() => {
                  setmodal_standard(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {modal_data.data?.holders_list?.length > 0 &&
                modal_data.data?.holders_list[0].amount 
                ? 
                  <TableContainer
                    data={sortAndGetTop10_2(modal_data)}
                    columns={columns_holders_list}
                  />
                : (
                  <Chart chartData={transformArray(modal_data)}/>
                )
              }
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  tog_standard();
                }}
                className="btn btn-secondary "
                data-dismiss="modal"
                style={{background: '#212227', border:0}}
              >
                Close
              </button>
            </div>
          </Modal>

          <TableContainer
              // data={filteredData || []}
              data={isFiltered ? addHolderParameter(filterDatasFun(filteredData)) : addHolderParameter(filteredData) || []}
              columns={checkWalletsStatsExistence(filteredData) ? columns : removeItemsById(columns, idsToRemove)}
              isPagination={true}
              paginationWrapper={'pagination'}
          />
          
        </Container>
      </div>
      <Tooltip id="create_timestamp" />
      <Tooltip id="checkboxes" place="bottom" />
    </React.Fragment>
  );
};

Bondinglive.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Bondinglive);
