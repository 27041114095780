import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

import { connect } from "react-redux";

import { Link } from "react-router-dom";

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import './style/header.css'

//i18n
import { withTranslation } from "react-i18next";
import scanmeme from './../../pages/Authentication/img/scanmeme.svg'

import Ticker from "./Ticker";
import GaugeChart from 'react-gauge-chart'
import { Tooltip } from 'react-tooltip'

const Header = props => {
  // const [menu, setMenu] = useState(false);
  const [isSearch, setSearch] = useState(false);
  const authToken = sessionStorage.getItem('authToken');
  const [baseData, setBaseData] = useState(null)

  const getDashData = async () => {
    // const url = 'https://cors-anywhere.herokuapp.com/https://api.scan.meme/api/dashboard/daily_stats';
    const url = 'https://api.scan.meme/api/dashboard/daily_stats'
    let headers = {
      'Content-Type': 'application/json'
    };

    if (authToken) {
      headers['Authorization'] = authToken;
    }

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: headers
      });
    
      const data = await response.json();
      setBaseData(data.stats)  

    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    getDashData();
    let interval = setInterval(() => {
      getDashData();
    }, 600000);
    return () => clearInterval(interval);
  }, []);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const chartStyle = {
    height: 50,
    width: 95,
    paddingTop: 5
  }

  return (
    <React.Fragment>
      <header id="page-topbar" style={{background: "#000"}}>
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box" style={{padding: '0 0 0 .9rem'}}>
              <Link to="/" className="logo logo-light">
                <span className="logo-lg">
                  <img src={scanmeme} alt="" height="40"/>
                </span>
              </Link>

              <Link to="/" className="logo logo-dark">
                <span className="logo-lg">
                  <img src={scanmeme} alt="" height="40" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              data-toggle="collapse"
              onClick={() => {
                props.toggleLeftmenu(!props.leftMenu);
              }}
              data-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            {/* <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                />
                <span className="bx bx-search-alt" />
              </div>
            </form> */}
          </div>
          <div className="d-flex">
            {baseData &&
              <span
                data-tooltip-id="fear_greed"
                data-tooltip-html={`<span style="text-align: center; display: block">FEAR/GREED index of SunPump platform<br/>based on volume, holders, tokens<br/> 
                  <b style="font-size: 15px">${(baseData.hour.fear_greed_index).toFixed(2)}%</b></span>`} // contract_address
              >
                <GaugeChart 
                  id="gauge-chart1" 
                  style={chartStyle}
                  colors={['rgb(220, 31, 29)', 'rgb(216, 183, 68)', 'rgb(71, 154, 112)']}
                  animate={false} 
                  nrOfLevels={3} 
                  percent={baseData.hour.fear_greed_index / 100} 
                  needleColor="#fff"
                  hideText={true}
                />             
              </span>            
            }
            <Tooltip id="fear_greed" />
          </div>
          <div className="slider">
            {baseData && <Ticker data={baseData}/>}
          </div>

          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
                onClick={() => setSearch(!isSearch)}
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  isSearch
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={props.t("Search") + "..."}
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <LanguageDropdown />

            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                className="btn header-item noti-icon "
                onClick={() => {
                  toggleFullscreen();
                }}
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" style={{color: '#fff'}}/>
              </button>
            </div>
            <div style={{minWidth: '130px'}}>
              <ProfileMenu />
            </div>

            <div className="dropdown d-inline-block">
              <button
                onClick={() => {
                  props.showRightSidebarAction(!props.showRightSidebar);
                }}
                type="button"
                className="btn header-item noti-icon right-bar-toggle "
              >
                <i className="bx bx-cog" style={{color: '#fff'}}/>
              </button>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header));
