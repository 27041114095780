import React, { useState } from "react"
import { Container, Row, Col } from "reactstrap"
import {versions} from './version'
import {
  Modal,
} from "reactstrap";
import './style/footer.css'

const Footer = () => {
  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_data, setModal_data] = useState(null)
  const [modal_title, setModal_title] = useState(null)

  const tog_standard = () => {
    setmodal_standard(!modal_standard);
    if(!modal_standard){
      setModal_data(null)
      setModal_title(null)
    }
  };

  const changelog = (data) => {
    return (
      data.map((version, index) => (
        <div key={index} className="change-log__item">
          <p className="change-log__version">v{version.version}</p>
          {version.logs?.map((log, index) => (
            <div key={index} className="change-log__scope">
              {log.scope && (
                <p className="change-log__scope-name">{log.scope}</p>
              )}
              <ul className="change-log__changes">
                {log.changes.map((change, index) => (
                  <li key={index} className="change-log__change">
                    {change}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ))
    )
  }

  const policy = () => {
    return (
      <div className="policy">

        <div className="section">
          <h2 style={{marginTop: 0}}>Disclaimer:</h2>
          <p>Scan.meme is an <strong>informational platform</strong> designed to provide users with data and analytics related to the Sunpump.meme ecosystem. The information provided on Scan.meme is intended solely for informational purposes and does not constitute financial advice, investment recommendations, or any form of buy or sell call to action.</p>
        </div>

        <div className="section">
          <h2>Responsibility:</h2>
          <ul>
            <li><strong>Do Your Own Research (DYOR):</strong> Users are strongly advised to conduct their own thorough research and due diligence before making any financial decisions. Scan.meme provides tools and data to aid in your research, but it is the user's responsibility to verify the accuracy and relevance of this information to their specific circumstances.</li>
            <li><strong>100% User Responsibility:</strong> By using Scan.meme, you acknowledge and agree that you are 100% responsible for any actions or decisions you make based on the information provided by the platform. Scan.meme, its creators, and affiliates will not be held liable for any losses, damages, or consequences arising from your use of the platform.</li>
            <li><strong>No Guarantee of Accuracy:</strong> While Scan.meme strives to provide accurate and up-to-date information, we do not guarantee the completeness, reliability, or accuracy of the data. Market conditions can change rapidly, and past performance is not indicative of future results.</li>
          </ul>
        </div>

        <div className="section">
          <h2>Acceptance of Terms:</h2>
          <p>By accessing and using Scan.meme, you agree to the terms and conditions outlined in this policy. If you do not agree with these terms, you should not use the platform.</p>
        </div>

        <div className="section">
          <p className="cytat">"I Accept the Policy and Understand My Responsibilities"</p>
        </div>

        <div className="section">
          <p>This policy is designed to protect both the platform and its users by clearly stating that Scan.meme is purely an informational tool and that users must take full responsibility for their financial decisions.</p>
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} 
              © Scan.meme <button onClick={()=>{
                tog_standard()
                setModal_data(changelog(versions))
                setModal_title('Changelog')
              }} className="link">v{versions[0].version}</button>
            </Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                <button onClick={()=>{
                  tog_standard()
                  setModal_data(policy())
                  setModal_title('Privacy Policy')
                }} className="link">Privacy Policy</button> | Terms of Service
              </div>
            </Col>
          </Row>
        </Container>
      </footer>

      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
        scrollable={true}
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {modal_title}
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
           >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {modal_data}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
            style={{background: '#212227', border:0}}
          >
            Close
          </button>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default Footer
