export const versions = [
  {
    version: "0.63",
    logs: [
      {
        scope: "",
        changes: [
          'Login Page Mobile Optimization: Adapted the login page for better usability and display on mobile devices.'
        ],
      },
    ],
    author: "RK",
  },
  {
    version: "0.62",
    logs: [
      {
        scope: "",
        changes: [
          'Data Presentation Update on Top Bar Dashboard: Revised the data presentation format on the top bar of the dashboard for improved clarity and usability.'
        ],
      },
    ],
    author: "RK",
  },
  {
    version: "0.61",
    logs: [
      {
        scope: "",
        changes: [
          'Change Tronlink address in Holders Modal'
        ],
      },
    ],
    author: "RK",
  },
  {
    version: "0.60",
    logs: [
      {
        scope: "",
        changes: [
          'Updated Access Level Labels: Renamed access level labels from Basic, Advanced, and Pro to Core, Advanced, and Master for better clarity and consistency.'
        ],
      },
    ],
    author: "RK",
  },
  {
    version: "0.59",
    logs: [
      {
        scope: "",
        changes: [
          'Increased Bubble Map Window Size: Enlarged the bubble map window to enhance data visibility and improve user interaction with map elements.'
        ],
      },
    ],
    author: "RK",
  },
  {
    version: "0.58",
    logs: [
      {
        scope: "",
        changes: [
          'New Columns for Wallet AI Scanner: Added new columns to display results from the Wallet AI Scanner,',
          'Bubblemaps Column and Modal: Introduced a new Bubblemaps column along with a modal that displays the map,',
          'Login Changes - Access Tiers: Implemented Access Tiers for login: Basic, Advanced, and Pro,',
          'Premium Data Adjustment: Adjusted premium data access based on user permissions,',
          'Top Bar Dashboard: Added a new top bar for the dashboard.'
        ],
      },
    ],
    author: "RK",
  },
  {
    version: "0.57d",
    logs: [
      {
        scope: "",
        changes: [
          'Tooltips for Checkboxes: Added tooltips to provide descriptions for each checkbox filter,',
          'Checkbox Label Renaming: Updated the labels on checkboxes for clearer descriptions,',
          'Filter Suffixes: Added suffixes to filters when a value is entered to clarify the filter criteria,',
          'Wallet Address Links: Added links to wallet addresses in the Holders list and the main list for easy access,',
          'UI Light Improvement: Enhanced the styling of the unordered list for better visual appearance,',
          'Removed Top Bar Style Selection: Removed the option to select styles for the top bar.'
        ],
      },
    ],
    author: "RK",
  },
  {
    version: "0.56",
    logs: [
      {
        scope: "",
        changes: [
          'Sorting Improvement: Enhanced the sorting functionality for more accurate and efficient results.',
        ],
      },
    ],
    author: "RK",
  },
  {
    version: "0.54",
    logs: [
      {
        scope: "",
        changes: [
          'New Filter Options: Added checkboxes for filters: Web TG, Web WWW, Web X, and Dev Sold,',
          'Holders Filter: Introduced a filter for "Holders more than X",',
          'Duplicates Filter: Introduced a filter for "Duplicates less than X",',
          'Tokens Created Filter: Introduced a filter for "Tokens created less than X",',
          'Modals for Login, Guide, and Policy: Added new modals for login, guide, and policy information,',
          'UI Update: General user interface improvements and enhancements.',
        ],
      },
    ],
    author: "RK",
  },
  {
    version: "0.53",
    logs: [
      {
        scope: "",
        changes: [
          "Data Refresh Interval Change: Updated data refresh rate to every 3 seconds."
        ],
      },
    ],
    author: "RK",
  },
  {
    version: "0.52a",
    logs: [
      {
        scope: "",
        changes: [
          "Login UI Update: Redesigned the user interface for the login screen,",
          "Holders Column Value Change: Updated the \"Holders\" column to display the number of wallets."
        ],
      },
    ],
    author: "RK",
  },
  {
    version: "0.51",
    logs: [
      {
        scope: "",
        changes: [
          "Hide Price USD Column: The \"Price USD\" column has been hidden,",
          "Chart Relocation: Moved the chart display to the Market Cap section."
        ],
      },
    ],
    author: "RK",
  },
  {
    version: "0.50c",
    logs: [
      {
        scope: "",
        changes: [
          "Improved Filter Functionality: Enhanced the performance and accuracy of the filters for a better user experience."
        ],
      },
    ],
    author: "RK",
  },
  {
    version: "0.49b",
    logs: [
      {
        scope: "",
        changes: [
          "DEV Column Relocation: Moved the DEV column to appear before the HOLDERS column,",
          "DEV Column Tooltip: Added a tooltip to the DEV column explaining the color coding, similar to the description in the HOLDERS column,",
          "TOPHOLDER Column Addition: Added a new TOPHOLDER column placed after the HOLDERS column,",
          "TOKEN Created Column Placement: Moved the TOKEN Created column between the DEV and HOLDERS columns,",
          "Renamed Doubles to Duplicates: Changed the term \"Doubles\" to \"Duplicates\" for better clarity,",
          "Tooltips for All Column Headers: Added tooltips to all column headers to provide additional information,",
          "Added Policy to Footer: Included a policy link in the footer of the platform.",
          "Authorization and Login via Single Button: Simplified the user experience by integrating authorization and login into a single button. Let me know if there's anything else you need!"
        ],
      },
    ],
    author: "RK",
  },
  {
    version: "0.48",
    logs: [
      {
        scope: "",
        changes: [
          "Renamed to Scan.Meme: Updated the platform name to \"Scan.Meme\" as part of the first phase of the UI changes.",
        ],
      },
    ],
    author: "RK",
  },
  {
    version: "0.47",
    logs: [
      {
        scope: "",
        changes: [
          "Holders Tooltip Description Improvement: Enhanced the tooltip description for the Holders column to provide clearer and more detailed information,",
          "Creator Wallet Marking in HOLDERS POP UP: Marked the creator's wallet in the HOLDERS POP UP if it is in the Top 10 holders of the token. Added \"DEV WALLET\" label and color icon."
        ],
      },
    ],
    author: "RK",
  },
  {
    version: "0.46",
    logs: [
      {
        scope: "",
        changes: [
          "Play/Stop refresh counter",
          "Default Sorting by Buy/Sell (1 minute): The list now defaults to sorting by the latest Buy/Sell transactions within the past minute,",
          "Token Contract Address Copy: Added a \"Copy Token Contract Address\" option next to the token name, allowing users to easily copy the contract address,",
          "DEV Column Enhancement: Introduced a DEV column showing the percentage of tokens held by the meme creator. Added a button for copying the creator's address (Creator Address),",
          "Column Sorting Color: Added color indicators to highlight the sorted column.,",
          "Holders Table Tooltip: Added a tooltip to the table header of the Holders column providing additional information,",
          "Fix - you have been logged out because authentication was unsuccessful."
        ],
      },
    ],
    author: "RK",
  },
  {
    version: "0.45",
    logs: [
      {
        scope: "",
        changes: [
          "A counter measuring the time until the next refresh.",
        ],
      },
    ],
    author: "RK",
  },
  {
    version: "0.44d",
    logs: [
      {
        scope: "",
        changes: ["Button Placement Update."],
      },
    ],
    author: "RK",
  },
  {
    version: "0.43",
    logs: [
      {
        scope: "",
        changes: ["Changelog Feature,", "You have been logged out because authentication was unsuccessful."],
      },
    ],
    author: "RK",
  },
]